import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Login from './views/common/LoginView.vue';
import { Domain, RouteNames } from './types/common/enums';
import { request } from './utils/common/request';
import { Component } from 'vue-router/types/router';
import dayjs from 'dayjs';
import { isLvPostPartner } from '@/utils/lv/isLvPostPartner';

Vue.use(VueRouter);

const LoggedIn = () => import('./LoggedIn.vue');
const Entities = () => import('./views/common/EntitiesView.vue');
const Applications = () => import('./views/common/ApplicationsView.vue');
const Invitations = () => import('./views/common/InvitationsView.vue');
const Commissions = () => import('./views/common/CommissionsView.vue');
const SearchUserInvitation = () => import('./views/common/SearchUserInvitationView.vue');
const RegisterUser = () => import('./views/common/RegisterUserView.vue');
const EditProfile = () => import('./views/common/EditProfileView.vue');
const EditPassword = () => import('./views/common/EditPasswordView.vue');
const ResetPassword = () => import('./views/common/ResetPasswordView.vue');
const GiftCards = () => import('./views/common/GiftCardsView.vue');
const BonusApplications = () => import('./views/common/BonusApplicationsView.vue');
const Documents = () => import('./views/common/DocumentsView.vue');
const IndicativeCalculator = () => import('./views/common/IndicativeCalculatorView.vue');
const AddUser = () => import('./views/common/AddUserView.vue');
const AddChannel = () => import('./views/common/AddChannelView.vue');
const EditUser = () => import('./views/common/EditUserView.vue');
const EditChannel = () => import('./views/common/EditChannelView.vue');
const Residuals = () => import('./views/fi/ResidualsView.vue');
const PaidCommissionsReport = () => import('./views/fi/PaidCommissionsReport.vue');
const AmlTest = () => import('./views/lv/AmlTest.vue');
const Unavailable = () => import('./views/lt/Unavailable.vue');

const Application = () => import('./views/common/ApplicationView.vue');
const Error = () => import('./views/common/ErrorView.vue');

// internal
const AddPartner = () => import('./views/internal/AddPartnerView.vue');
const EditPartner = () => import('./views/internal/EditPartnerView.vue');
const AddCommission = () => import('./views/internal/AddCommisionView.vue');
const UserBonuses = () => import('./views/internal/UserBonusesView.vue');
const AddUserBonus = () => import('./views/internal/AddUserBonusView.vue');
const GiftCardManagement = () => import('./views/internal/GiftCardManagementView.vue');
const UserBonusReport = () => import('./views/internal/UserBonusReportView.vue');
const AddPartnerUserApplicationBonus = () => import('./views/internal/AddPartnerUserApplicationBonusView.vue');
const ManageDocuments = () => import('./views/internal/ManageDocumentsView.vue');
const AddDocument = () => import('./views/internal/AddDocumentView.vue');
const PartnersReport = () => import('./views/internal/PartnersReportView.vue');
const UsersReport = () => import('./views/internal/UsersReportView.vue');
const PartnerProducts = () => import('./views/internal/PartnerProducts.vue');
const QuestionnaireReport = () => import('./views/internal/QuestionnaireReport.vue');
const ManageQuestionnaire = () => import('./views/internal/ManageQuestionnaire.vue');
const AddQuestion = () => import('./views/internal/AddQuestionnaireQuestion.vue');
const EditQuestion = () => import('./views/internal/EditQuestionnaireQuestion.vue');
const ThankYouPage = () => import('./views/lv/ThankYouPage.vue');
const Banners = () => import('./views/internal/BannersView.vue');

// LT
const DocumentArchiving = () => import('./views/lt/DocumentArchiving.vue');

// Internet Bank access blocking
const AccessBlocking = () => import('./views/internetBanking/accessBlocking.vue');

const publicRoutes = [
  RouteNames.LOGIN,
  RouteNames.INVITATION,
  RouteNames.REGISTER,
  RouteNames.RESET_PASSWORD,
  RouteNames.ERROR,
  RouteNames.UNAVAILABLE,
];

const routes: Array<RouteConfig> = [
  {
    path: '/log-in',
    name: RouteNames.LOGIN,
    component: Login,
  },
  {
    path: '/invitation',
    name: RouteNames.INVITATION,
    component: SearchUserInvitation,
  },
  {
    path: '/register/:invitationId',
    name: RouteNames.REGISTER,
    component: RegisterUser,
    props: (route) => ({
      invitationId: route.params.invitationId,
    }),
  },
  {
    path: '/password/reset/:passwordResetToken',
    name: RouteNames.RESET_PASSWORD,
    component: ResetPassword,
    props: (route) => ({
      passwordResetToken: route.params.passwordResetToken,
    }),
  },
  {
    path: '/error',
    name: RouteNames.ERROR,
    component: Error,
  },
  {
    path: 'unavailable',
    name: RouteNames.UNAVAILABLE,
    component: Unavailable,
  },
  {
    path: '/',
    name: 'Base',
    component: LoggedIn,
    children: [
      {
        path: 'application',
        name: RouteNames.APPLICATION,
        component: Application,
      },
      {
        path: 'thank-you-page',
        name: RouteNames.THANK_YOU_PAGE,
        component: ThankYouPage,
      },
      {
        path: 'entities',
        name: RouteNames.ENTITIES,
        component: Entities,
        children: [
          {
            name: RouteNames.CHANNEL,
            path: 'create-channel',
            component: AddChannel as Component,
          },
          {
            name: RouteNames.USER,
            path: 'create-user-invitation',
            component: AddUser,
          },
          {
            name: RouteNames.EDIT_CHANNEL,
            path: 'edit-channel',
            component: EditChannel,
            props: true,
          },
          {
            name: RouteNames.EDIT_USER,
            path: 'edit-user',
            component: EditUser,
            props: true,
          },
          {
            name: RouteNames.PARTNER,
            path: 'create-partner',
            component: AddPartner,
          },
          {
            name: RouteNames.EDIT_PARTNER,
            path: 'edit-partner',
            component: EditPartner,
            props: true,
          },
        ],
      },
      {
        path: 'commissions',
        name: RouteNames.COMMISSIONS,
        component: Commissions,
        children: [
          {
            path: 'add-commission',
            name: RouteNames.ADD_COMMISSION,
            component: AddCommission,
          },
        ],
      },
      {
        path: 'user-bonuses',
        name: RouteNames.USER_BONUSES,
        component: UserBonuses,
        children: [
          {
            path: 'add-user-bonus',
            name: RouteNames.ADD_USER_BONUS,
            component: AddUserBonus,
          },
        ],
      },
      {
        path: 'user-bonus-report',
        name: RouteNames.USER_BONUS_REPORT,
        component: UserBonusReport,
        children: [
          {
            path: 'add-partner-user-application-bonus',
            name: RouteNames.ADD_PARTNER_USER_BONUS,
            component: AddPartnerUserApplicationBonus,
          },
        ],
      },
      {
        path: 'manage-documents',
        name: RouteNames.MANAGE_DOCUMENTS,
        component: ManageDocuments,
        children: [
          {
            path: 'add-document',
            name: RouteNames.ADD_DOCUMENT,
            component: AddDocument,
          },
        ],
      },
      {
        path: 'residuals',
        name: RouteNames.RESIDUALS,
        component: Residuals,
      },
      {
        path: 'paid-commissions-report',
        name: RouteNames.PAID_COMMISSIONS_REPORT,
        component: PaidCommissionsReport,
      },
      {
        path: 'users-report',
        name: RouteNames.USERS_REPORT,
        component: UsersReport,
      },
      {
        path: 'questionnaire-report',
        name: RouteNames.QUESTIONNAIRE_REPORT,
        component: QuestionnaireReport,
      },
      {
        path: 'manage-questionnaire',
        name: RouteNames.MANAGE_QUESTIONNAIRE,
        component: ManageQuestionnaire,
        children: [
          {
            path: 'edit-question',
            name: RouteNames.EDIT_QUESTION,
            component: EditQuestion as Component,
            props: true,
          },
          {
            path: 'add-question',
            name: RouteNames.ADD_QUESTION,
            component: AddQuestion,
          },
        ],
      },
      {
        path: 'partners-report',
        name: RouteNames.PARTNERS_REPORT,
        component: PartnersReport,
      },
      {
        path: 'gift-card-management',
        name: RouteNames.GIFT_CARD_MANAGEMENT,
        component: GiftCardManagement,
      },
      {
        path: '/document-archiving',
        name: RouteNames.DOCUMENT_ARCHIVING,
        component: DocumentArchiving,
      },
      {
        path: '/partner-products',
        name: RouteNames.PARTNER_PRODUCTS,
        component: PartnerProducts,
      },
      {
        path: 'applications',
        name: RouteNames.APPLICATIONS,
        component: Applications,
      },
      {
        path: 'invitations',
        name: RouteNames.INVITATIONS,
        component: Invitations,
      },
      {
        path: 'profile',
        name: RouteNames.PROFILE,
        component: EditProfile,
      },
      {
        path: 'password',
        name: RouteNames.PASSWORD,
        component: EditPassword,
      },
      {
        path: 'gift-cards',
        name: RouteNames.GIFT_CARDS,
        component: GiftCards,
      },
      {
        path: 'bonus-applications',
        name: RouteNames.BONUS_APPLICATIONS,
        component: BonusApplications,
      },
      {
        path: 'documents',
        name: RouteNames.DOCUMENTS,
        component: Documents,
      },
      {
        path: 'indicative-calculator',
        name: RouteNames.INDICATIVE_CALCULATOR,
        component: IndicativeCalculator,
      },
      {
        path: '/aml-test',
        name: RouteNames.AML_TEST,
        component: AmlTest,
      },
      {
        path: '/banners',
        name: RouteNames.BANNERS,
        component: Banners,
      },
      {
        path: '/access-blocking',
        name: RouteNames.ACCESS_BLOCKING,
        component: AccessBlocking,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });

  const userData = localStorage.user ? JSON.parse(localStorage.user) : {};

  if (localStorage.isLoggedIn && !to.matched.length) {
    router.replace({
      name: RouteNames.APPLICATIONS,
    }).catch(() => {});
  } else if (!localStorage.isLoggedIn && !publicRoutes.includes(to.name as RouteNames)) {
    if (to.query.externalId) {
      return router.replace({
        name: RouteNames.LOGIN,
        query: {
          externalId: to.query.externalId,
        },
      }).catch(() => {});
    }

    router.replace({
      name: RouteNames.LOGIN,
    }).catch(() => {});
  } else if (!publicRoutes.includes(to.name as RouteNames)) {
    request.get('/alive').catch(() => {
      const userSavedColumns = localStorage.getItem('selectedApplicationColumns') ?? '[]';
      localStorage.clear();
      if (process.env.VUE_APP_IS_DEV !== 'TRUE') {
        localStorage.setItem('selectedApplicationColumns', userSavedColumns);
      }
      next('log-in');
    });
  }
  if (window.config.DOMAIN === Domain.LV &&
      to.path !== '/log-in' &&
      to.path !== '/aml-test' &&
      userData.active &&
      (!userData.amlTrainingDate ||
          (userData.amlTrainingDate &&
              Math.abs(dayjs(userData.amlTrainingDate).diff(new Date, 'day')) >= 365)
      ) &&
      !isLvPostPartner()
  ) {
    router.replace({
      name: RouteNames.AML_TEST,
    }).catch(() => {});
  }
  if (to.path === '/carhp') {
    router.replace({
      name: RouteNames.APPLICATION,
      query: to.query,
    }).catch(() => {});
  }
  if (to.path.match(/\/\/+/g)) {
    next('applications');
  }
  next();
});

export default router;
